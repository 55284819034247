import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Track from "../../Assets/adts/track.png";
import "./adts.css";

function ADTS() {
  return (
    <section id="adts">
      <Container>
        <div className="main-heading">
          Automated Driving Test System in Kerala (ADTS)
        </div>
        <div className="content">
          <p>
            ADTS is a sensor-based electronic device that automatically
            evaluates driving skills on a predetermined track to accommodate
            motor vehicles. The tracks are created in various forms so that the
            test applicant can manoeuvre the vehicle without coming into contact
            with the sensors. When the vehicle comes into contact with a sensor,
            signals are sent to the control center, and the marks are subtracted
            accordingly. Any driver who touches more sensors than the specified
            number will fail the test.
          </p>
          <Row>
            <Col lg={12}>
              <div className="abc">
                <img className="img-fluid" src={Track} alt="track" />
              </div>
            </Col>
          </Row>
          <p>
            The sensors are housed in tough enclosures, with a sensor pole
            positioned on the foundation structure along the track's borders,
            which sends the signal when the pole is dislodged from its base
            unit. These sensors, each with their own unique identity,
            communicate wirelessly across an RF network.
          </p>
          <p className="mt-4">
            These tracks contain traffic signals placed along the track to
            measure the test-drive applicant's responsiveness to the various
            signal conditions. Using RFID cards provided during the registration
            procedure, all candidates are validated and allowed for test track
            admission. Individual track testing is recorded by specific track
            cameras installed on the racetrack during testing.
          </p>
        </div>
      </Container>
    </section>
  );
}

export default ADTS;
