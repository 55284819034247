import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Arrow from "../../Assets/description/arrow.svg";
import Dots from "../../Assets/description/dot1.svg";
import Dot from "../../Assets/description/dot2.svg";
import "./desc.css";

function Desc() {
  return (
    <section id="description">
      <div className="bg-img">
        <Container>
          <Row>
            <Col lg={6}>
              <img className="arrow" src={Arrow} alt="arrow" />
              <div className="quote">
                <p>We are here to help you become a great driver</p>
              </div>
              <img className="dots" src={Dots} alt="dots" />
            </Col>
          </Row>
          <div className="mech-desc-box">
            <Container>
              <div className="mech-desc-heading ">
                Mechanical Training by qualified trainers
              </div>
              <div className="desc-content ">
                <p>
                  With an ever-increasing number of cars on the road, it's
                  critical that drivers are well-trained and informed on the
                  mechanics of the machinery they're driving. This is where
                  mechanical training for vehicles comes into play. Students may
                  be confident that they are receiving the finest possible
                  teaching on the technical elements of their cars since
                  certified personnel is on hand to give driver education and
                  training.
                </p>
                <p>
                  {" "}
                  Automobile mechanical training is normally separated into two
                  components. The first is the knowledge component, which
                  includes the theory and rules connected to vehicle operation.
                  The second component is the practical component, which entails
                  actual hands-on experience and learning of the various
                  mechanical components and systems. Both aspects of the
                  training are critical because they serve to guarantee that the
                  student understands the mechanics of their vehicle and can
                  operate it safely and responsibly.{" "}
                </p>
                <p>
                  Qualified personnel are required for the practical component
                  of the training. These employees must have a thorough grasp of
                  a vehicle's mechanical systems and components, as well as
                  prior experience offering training and assistance on the
                  appropriate use of the equipment. They should also have a
                  thorough awareness of vehicle safety legislation and be able
                  to give guidance and teaching to ensure that all pupils are
                  driving safely and responsibly.{" "}
                </p>
                <p>
                  Training drivers on fuel efficiency techniques is essential to
                  reducing long-term fuel costs for fleets and companies. When
                  drivers are trained to drive more efficiently, they can follow
                  routes that require less fuel and modify their driving habits
                  to reduce fuel consumption. Fuel efficiency training also has
                  environmental benefits, as it can reduce overall carbon
                  emissions from driving.
                </p>
              </div>
            </Container>
          </div>
          <div className="defence-desc-box mt-4">
            <Container>
              <div className="defence-desc-heading ">
                Defensive Driving Training
              </div>
              <div className="defence-desc-content ">
                <p>
                  Defensive driving is an essential skill for all drivers. It
                  assists you in remaining safe on the road, anticipating
                  possible risks and reacting promptly, and reducing the
                  likelihood of an accident. Defensive driving is adhering to
                  traffic laws, paying great attention to other drivers, and
                  learning how your vehicle reacts in various scenarios.{" "}
                </p>
                <p>
                  You will be able to notice and respond to harmful situations
                  before they become a problem if you master defensive driving.
                  You will also be able to operate your car swiftly and
                  efficiently in an emergency circumstance. You will learn to
                  anticipate other drivers' actions and potential hazards on the
                  road.{" "}
                </p>
                <p>
                  Driving defensively is extremely vital in bad weather, such as
                  rain, snow, and ice roadways. It is critical to have the
                  ability to operate your car under these situations in order to
                  be safe.
                </p>
                <p>
                  {" "}
                  Defensive driving can also help you save money on your auto
                  insurance. Insurance companies acknowledge the value of
                  defensive driving and may provide discounts to drivers who
                  have completed defensive driving courses.
                </p>
                <p>
                  {" "}
                  Ultimately, defensive driving is a valuable ability to have as
                  a driver since it may help you stay safe on the road. It may
                  also assist you in saving money on auto insurance.
                </p>
                <img className="dots" src={Dot} alt="dots" />
              </div>
            </Container>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Desc;
