import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./Contact.css";
import axios from "../network/api.js";
import { baseUrl } from "../network/constants/Constants";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [message, setMessage] = useState("");
  const [contactError, setContactError] = useState("");

  function handleMessage(e) {
    var filter =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (userName.length === 0) {
      setContactError("Please enter your name!");
    } else if (message.length === 0) {
      setContactError("Please enter a message!");
    } else {
      submitMail();
    }
  }

  const submitMail = () => {
    setLoading(true);
    const payload = {
      name: userName,
      message: message,
    };

    console.log("payload: " + JSON.stringify(payload));
    axios
      .post(baseUrl + "webapp/contact/upload/", payload)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(true);
      });
  };

  return (
    <section id="contact">
      <div>
        <Container>
          <div className="contact-heading">Contact Us</div>
          <Row className="contact-row">
            <Col lg={6}>
              <div className="contact-form">
                <form>
                  <div className="form-head">Contact Form</div>
                  <div>
                    <input
                      id="name"
                      class="form-id"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required=""
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                  <div>
                    <textarea
                      id="message"
                      class="form-id mt-4"
                      type="text"
                      name="subject"
                      rows="4"
                      placeholder="Message"
                      required=""
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  {contactError && contactError((contact, index) => {})}
                  <div className="submit-btn">
                    <button className="submit mt-4" onClick={handleMessage}>
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </Col>
            <Col lg={6}>
              <Row lg={6}>
                <div className="map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d125531.57912764483!2d76.14084748526177!3d10.51154576306976!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7ee15ed42d1bb%3A0x82e45aa016ca7db!2sThrissur%2C%20Kerala!5e0!3m2!1sen!2sin!4v1678511661824!5m2!1sen!2sin"
                    className="map-location"
                    // width="534"
                    height="386"
                    style={{ border: "none" }}
                  ></iframe>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Contact;
