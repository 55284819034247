import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../Assets/footer/Logo.svg";
import Fb from "../../Assets/Header/facebook.svg";
import Insta from "../../Assets/Header/insta.svg";
import Linkedin from "../../Assets/Header/linkedin.svg";
import Twitter from "../../Assets/Header/twitter.svg";
import Youtube from "../../Assets/Header/youtube.svg";

import "./Footer.css";

function Footer() {
  return (
    <section id="footer">
      <div className="Footer-section">
        <Container>
          <Row className="footer-row">
            <Col lg={4} className="footer-logo">
              <img className="footer-logo" src={Logo} alt="logo" />
              <div className="footer-address">
                <span className="address mt-4">
                  ADDRESS:<span className="">&nbsp;Pulikkan Tower, Kanjany road, Outpost, Ayyanthole, Thrissur, 680003</span> 
                </span>
                <span className="email mt-4">
                  EMAIL:{" "}
                  <a href="mailto:motomiaindia@gmail.com">
                  &nbsp;motomiaindia@gmail.com
                  </a>
                </span>
                <span className="phone mt-4">
                  Mobile:{" "}
                  <a href="tel:+91 93888 76125">&nbsp;+91 93888 76125 ,</a>
                  <a href="tel:+91 94472 76105">&nbsp;+91 94472 76105</a>
                </span>
              </div>
            </Col>
            <Col lg={4} className="footer-nav ">
              <div className="footer-nav-head">NAVIGATIONS</div>
              <p className="mt-4">
                <a href="/#home">HOME</a>
              </p>
              <p>
                <a href="/#adts">ADTS</a>
              </p>
              <p>
                <a href="/#aboutus">ABOUT US</a>
              </p>
              <p>
                <a href="/#gallery">GALLERY</a>
              </p>
            </Col>
            <Col lg={4} className="footer-social-media">
              <div className="media">FOLLOW US</div>
              <p className="mt-4">
                <a
                  href="https://www.facebook.com/profile.php?id=100092236123792&mibextid=ZbWKwL"
                  class="float"
                  target="_blank"
                >
                  <img className="facebook-img" src={Fb} alt="fb" />
                  &nbsp; FACEBOOK
                </a>
              </p>
              <p>
                <a
                  href="https://instagram.com/motomia.in?igshid=ZDdkNTZiNTM="
                  class="float"
                  target="_blank"
                >
                  <img className="insta-img" src={Insta} alt="insta" />
                  &nbsp; INSTAGRAM
                </a>
              </p>
              <p>
                <a
                  href="https://www.linkedin.com/"
                  class="float"
                  target="_blank"
                >
                  <img className="linkedin-img" src={Linkedin} alt="linkedin" />
                  &nbsp; LINKEDIN
                </a>
              </p>
              <p>
                <a href="https://twitter.com/" class="float" target="_blank">
                  <img className="twitter-img" src={Twitter} alt="twitter" />
                  &nbsp; TWITTER
                </a>
              </p>
              <p>
                <a
                  href="https://youtube.com/@Motomia-nf9xr"
                  class="float"
                  target="_blank"
                >
                  <img className="youtube-img" src={Youtube} alt="youtube" />
                  &nbsp; YOUTUBE
                </a>
              </p>
            </Col>
          </Row>
        </Container>
        <div className="copyrights">
          <div className="pt-3">Copyright 2023 All Rights Reserved</div>
          <p>
            Website Designed & Developed By
            <a href="https://nthindex.com/">
              &nbsp;Nthindex Software Solutions LLP
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Footer;
