import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "../network/api.js";
import "./Gallery.css";
import { baseUrl } from "../network/constants/Constants";

function Gallery() {
  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    fetchGallery();
  }, []);

  const fetchGallery = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/gallerys/")
      .then((response) => {
        console.log(response.data);
        setLoading(true);

        setGallery(response.data);
        console.log("gallery" + response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <section id="gallery">
      <div>
        <Container>
          <div className="gallery-heading">Gallery</div>
          <div className="gallery-img">
            <Row className="gallery-row">
              {gallery &&
                gallery.map((galleryImg, index) => {
                  return (
                    <Col lg={4}>
                      <img
                        className="img1 img-fluid"
                        src={galleryImg.gallery}
                        alt="image"
                      />
                    </Col>
                  );
                })}
              {/* <Col lg={4}>
                <img className="img1 img-fluid" src={Image1} alt="image" />
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Gallery;
