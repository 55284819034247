import React from "react";
import { Container } from "react-bootstrap";
import About from "../../Assets/about/MOTOMIA_LOGO_ROUND.png";
import "./About.css";

function AboutUs() {
  return (
    <section id="aboutus">
      <div>
        <Container>
          <div className="about-heading">About Motomia</div>
          <div className="spring-image">
            <div className="arrow-image">
              <div className="spring">
                <div className="star-image">
                  <img className="about-img" src={About} alt="round image" />
                </div>
              </div>
            </div>
            <div className="about-content">
              <p>
                As part of the central government's new policy to establish
                accredited driving training centers and related businesses.
                MOTOMIA INDIA is here to establish, maintain, organize, conduct,
                provide, procure or make available driving schools or driving
                training institutes, teaching, training to drive automobiles,
                automobile handling, consulting about automobile licenses,
                documentation, and allied activities.
              </p>
              <p>
                we are here to Modern driving and maintenance of vehicles in
                accordance with the changes in the automotive sector from time
                to time, We are focusing on providing proper training to the
                drivers, which will make the roads safer for everyone. we are
                committed to making the servicing experience hassle-free & easy
                for everyone. our services will include Driving training,
                Vehicle maintenance, traffic education, Road safety and
                behaviour, First aid and golden hour, Fuel efficiency, safe
                driving, defensive driving, etc.
              </p>
              <p>
                As we are in the Automotive industry, we have a team of
                professional driving trainers who provide training to our
                customers. We also have a team of people who work in the
                maintenance and repair of vehicles.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default AboutUs;
