import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
// import Carousel from "react-grid-carousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./AboutMoto.css";
import axios from "../network/api.js";
import { baseMediaUrl, baseUrl } from "../network/constants/Constants";

function AboutMotomia() {
  const [loading, setLoading] = useState(false);
  const [car, setCar] = useState([]);

  useEffect(() => {
    fetchCars();
  }, []);

  const fetchCars = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/vehiclelist/")
      .then((response) => {
        console.log(response.data);
        setLoading(true);

        setCar(response.data);
        console.log("cars" + response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section id="vehicle">
      <Container>
        <div className="car-heading">About Motomia</div>
      </Container>
      <Carousel responsive={responsive} loop={true} infinite={true} autoPlay={true} autoPlaySpeed={3000} rewindWithAnimation={true}>
        {car &&
          car.map((carsImg, index) => {
            return (
              <div className="carousel-ring">
                <div className="car-div">
                  <img
                    className="car"
                    src={carsImg.vehicleimage}
                    alt="First slide"
                  />
                  <p>{carsImg.vehiclename}</p>
                </div>
              </div>
            );
          })}
        {/* <div className="carousel-ring">
          <div className="car-div">
            <img className="car" src={Car2} alt="First slide" />
            <p>SUV</p>
          </div>
        </div> */}
      </Carousel>
    </section>
  );
}

export default AboutMotomia;
