import React from "react";
import "./Home.css";
import Header from "../Header/Header";
import Navigation from "../Navbar/Navbar";
import ADTS from "../ADTS/adts";
import AboutUs from "../About/About";
import Banner from "../Banner/Banner";
import Desc from "../Description/desc";
import AboutMotomia from "../AboutMototmia/AboutMoto";
import Gallery from "../Gallery/Gallery";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";

function Home() {
  return (
    <section id="home">
      <div>
        <Header/>
        <Navigation/>
        <Banner/>
        <ADTS/>
        <AboutUs/>
        <Desc/>
        <AboutMotomia/>
        <Gallery/>
        <Contact/>
        <Footer/>
      </div>
    </section>
  );
}

export default Home;
