import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "../network/api.js";
import { baseUrl } from "../network/constants/Constants";
import { Carousel } from "react-responsive-carousel";
import "./Banner.css";

function Banner() {
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState([]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  const fetchBanner = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/banners/")
      .then((response) => {
        console.log(response.data);
        setLoading(true);

        setBanner(response.data);
        console.log("banner" + response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showArrows={false}
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        const defStyle = { color: "white", cursor: "pointer" };
        const style = isSelected
          ? { ...defStyle, color: "black", background: "#06d6d0" }
          : { ...defStyle, background: "white", color: "grey" };
        return (
          <span
            style={style}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          >
            0{1 + index}
          </span>
        );
      }}
    >
      {banner &&
        banner.map((bannerImg, index) => {
          return (
            <div className="carousel-img">
              <img
                classNameName="d-block carousel-img img-fluid"
                src={bannerImg.banner}
                alt="First slide"
                style={{
                  margin: "auto",
                  width: "100%",
                  position: "relative",
                  zIndex: -1,
                }}
              />
            </div>
          );
        })}
    </Carousel>
  );
}

export default Banner;
