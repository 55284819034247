import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Logo from "../../Assets/Navbar/logo.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import jQuery, { nodeName } from "jquery";
import "./Navbar.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../network/constants/Constants";

function Navigation() {
  const { pathname, hash, key } = useLocation();
  const [loading, setLoading] = useState(false);
  const [leanersPdf, setLeanersPdf] = useState([]);
  const [syllabusPdf, setSyllabusPdf] = useState([]);

  useEffect(() => {
    //  }, []);

    // Get all sections that have an ID defined
    const sections = document.querySelectorAll("section[id]");

    // Add an event listener listening for scroll
    window.addEventListener("scroll", navHighlighter);

    function navHighlighter() {
      // Get current scroll position
      let scrollY = window.pageYOffset;

      // Now we loop through sections to get height, top and ID values for each
      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.pageYOffset;
        const sectionId = current.getAttribute("id");

        /*
    - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
    - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
    */
        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          document
            .querySelector(".navbar-nav a[href*=" + sectionId + "]")
            .classList.add("active");
        } else {
          document
            .querySelector(".navbar-nav a[href*=" + sectionId + "]")
            .classList.remove("active");
        }
      });
    }

    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          setTimeout(function () {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }, 100);
        }
      }, 100);
    }

    const navi = document.getElementById("navigation");
    const topOfNav = navi;
    const element = document.getElementById("stickyClass");

    return () => {
      function fixNav() {
        if (window.scrollY > topOfNav) {
          element.classList.add("nav-sticky");
        } else {
          element.classList.remove("nav-sticky");
        }
      }
      window.addEventListener("scroll", fixNav);
    };
  }, [pathname, hash, key]);

  useEffect(() => {
    fetchLearnersPdf();
  }, []);

  const fetchLearnersPdf = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/Documents/")
      .then((response) => {
        setLoading(false);

        setLeanersPdf(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };

  const leanersPdfDownload = (pdf) => {
    let url = pdf;

    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <section id="navbar">
      <div>
        <div className="border"></div>
        <Navbar bg="" expand="lg" className="navbar" id="stickyClass">
          <Container className="navigation">
            <a href="/#home">
              <img className="img" src={Logo} alt="brand-name" />
            </a>
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <div className="items">
                <Nav className="nav-details ">
                  <Nav.Link href="/#bannerimg" className="pe-lg-3">
                    HOME
                  </Nav.Link>
                  <Nav.Link href="/#adts" className="navLink pe-lg-3">
                    ADTS
                  </Nav.Link>
                  <Nav.Link href="/#aboutus" className="navLink pe-lg-3">
                    ABOUT US
                  </Nav.Link>
                  <Nav.Link href="/#gallery" className="navLink pe-lg-3">
                    GALLERY
                  </Nav.Link>
                  <Nav.Link href="/#vehicle" className="navLink pe-lg-3">
                    VEHICLE
                  </Nav.Link>
                  <Nav.Link href="/#contact" className="navLink pe-lg-3">
                    CONTACT US
                  </Nav.Link>

                  <Dropdown align={{ lg: 'end'}}>
                    <Dropdown.Toggle id="dropdown-basic" className="navbar-btn">
                      DOWNLOAD
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {leanersPdf &&
                        leanersPdf.map((dwd, index) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                leanersPdfDownload(dwd.pdf);
                              }}
                            >
                              {dwd.title}
                            </Dropdown.Item>
                          );
                        })}
                  
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </section>
  );
}

jQuery(function ($) {
  window.addEventListener("scroll", function () {
    let header = document.querySelector("nav");
    header.classList.toggle("nav-sticky", window.scrollY > 360);
  });
  // Code for changing active link on clicking
  var btns = $("navbar-nav > nav-link");
  if (btns.length != 0) {
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");

        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
  }

  //   $(document).ready(function () {
  //     $('.navbar-nav navLink a').click(function(e) {

  //         $('.navbar-nav navLink.active').removeClass('active');

  //         var $parent = $(this).parent();
  //         $parent.addClass('active');
  //         e.preventDefault();
  //     });
  // });
});

export default Navigation;
