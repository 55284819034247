import React from "react";
import Container from "react-bootstrap/Container";
import Clock from "../../Assets/Header/clock.svg";
import Share from "../../Assets/Header/share.svg";
import Facebook from "../../Assets/Header/facebook.svg";
import Insta from "../../Assets/Header/insta.svg";
import Linkedin from "../../Assets/Header/linkedin.svg";
import Twitter from "../../Assets/Header/twitter.svg";
import Youtube from "../../Assets/Header/youtube.svg";
import "./Header.css";

function Header() {
  return (
    <section id="header">
      <div>
        <div className="header-section">
          <Container>
          <div className="top">
            <div className="time">
              <img src={Clock} alt="clock" />
              <div className="training pt-3">
                <p>Training Time:</p>
              </div>
              <div className="timing pt-3">
                <p>6AM to 6PM</p>
              </div>
            </div>
            <div className="social-media">
              <img src={Share} className="icons" alt="share" />
              <div className="follow pt-3">
                <p>Follow Us On</p>
              </div>
              <a href="https://www.facebook.com/profile.php?id=100092236123792&mibextid=ZbWKwL" target="_blank">
                <img src={Facebook} className="icons" alt="facebook" />
              </a>
              <a href="https://instagram.com/motomia.in?igshid=ZDdkNTZiNTM=" target="_blank">
                <img src={Insta} className="icons" alt="Insta" />
              </a>
              <a href="https://in.linkedin.com/" target="_blank">
                <img src={Linkedin} className="icons" alt="Linkedin" />
              </a>
              <a href="https://twitter.com/" target="_blank">
                <img src={Twitter} className="icons" alt="Twitter" />
              </a>
              <a href="https://youtube.com/@Motomia-nf9xr" target="_blank">
                <img src={Youtube} className="icons" alt="Youtube" />
              </a>
            </div>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default Header;
